iframe {
    border-radius: 1em;
}

.arrows {
    height: 1.3em;
    opacity: 0.5;
    cursor:pointer;
}
.arrows:hover {
    opacity: 1;
}

#tracker-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
}

#tracker-container .circles{
    height: 2em;
    width: 2em;
    border-radius: 50%;
}

#subjects-inner-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1em;
}


#subjects-container-mini {
    padding: 1em;
    display: flex;
    flex-direction: row;
    gap: 1em;
    border: 2px solid #f3f3f3;
    border-radius: 10px;
    transition: 0.1s
}

.blank {
    border-radius: 10px;
    background: #f2f2f2
}

#subjects-container-mini img {
    height: 2em;
    width: 2em;
}


#subjects-container-mini:hover {
    background: #483699;
    border-color: #483699;
    color: #fff;
    box-shadow: 0px 0px 5px #ddd;
}


.intro-text {
    font-size: 45px;
    text-align: center;
    /* color: rgba(0, 0, 0, 0.7);         */
}

#upper-image {
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

#upper-image #intro-first {
    width: 90%;
    /* margin-top: 2em; */
}

#intro-first {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 100%;
    height: fit-content;
    box-shadow: 0px 0px 5px #888888;
    height: 60vh;
    /* margin-top: 5em; */
    border-radius: 1em;
}
.introduction-containerV2 {
    /* margin-top: 1em; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0em 5%;
    align-items: center;
    /* background: red; */
    gap: 1.5em; 
    position: relative;
    z-index: 2;
}

.introduction-containerV3 {
    /* margin-top: 1em; */
    /* width: 70%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* padding-top: 2em; */
    padding:2em 0;
    /* height: 100vh; */
    /* height:calc(100%-10em); */
    align-items: center;
    /* background: red; */
    gap: 1.5em; 
    position: relative;
    z-index: 2;
}



.introduction-containerV2 #welcome-sentence{
    font-size: 2em;
    text-align: center;
    color: rgba(0, 0, 0, 0.7);
}


.introduction-containerV2 #welcome-sentence-mini{
    font-size: 2em;
    text-align: center;
    color: rgba(255, 255, 255, 0.7);
}

#intro-first #intro-tag-header {
    color: #fff;
}



#background-slider-container {
    height: 60vh;
    width: 100%;
    z-index: 2;
    position:absolute;

}

#background-slider-filter {
    background-color: rgba(0, 0, 20, 0.6);
    height: 100%;
    width: 100%;
    z-index: 4;
    position:absolute;
    border-radius: 1em;
}

#background-slider-original {
    width: 100%;
    z-index: 1;
    position:absolute;
    border-radius: 1em;
    height: 30em;
}




.introduction-containerV2 h1{
    font-size: 5em;
    text-align: center;
}



.introduction-containerV2 #start-now{
    font-family: sfb;
    font-size: 2em;
    /* padding: 10px; */
    color: rgba(255, 255, 255, 0.7);
    background:rgba(255, 255, 255, 0.1);
    border: 3px solid rgba(255, 255, 255, 0.2); 
    /* background: rgba(255, 255, 255, 0.2); */
    /* border: none; */
    border-radius: 0.2em;
    cursor: pointer;
    outline: none;
}


.introduction-containerV2 #start-now:hover{

    /* border: 2px solid #443879; */
    color: #fff;
    background: #483699;
}

.quote {
    color:#443879;
}


.introduction-container {
    margin-top: 2em;
    padding: 20px;
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    /* flex-direction: row; */
    overflow: hidden;
    /* justify-content: space-between; */
    border: 1.5px solid #ddd;
    grid-gap: 10em; 
    box-shadow: 0px 0px 15px #eee;
    cursor: pointer;
    position: relative;
}

#portalbadge-container {
    margin-top: 0em;
    box-shadow: none;
    padding: 0px;
    display: flex;
    flex-direction: row;
    gap: 1em;
    justify-content: space-between;
}

#pb_img {
    height: 50px;
}

#portalbadge-container #text-container {
    flex: 2;
    padding: 20px;
}

#portalbadge-container button {
    background: #03C1EB;
}

#portalbadge-container #intro-text-a {
    color: #30A42E;
}

#portalbadge-container:hover {
    box-shadow: 0px 0px 15px #eee;
}

.introduction-container #text-container {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.introduction-container #welcome-sentence {
    /* font-family: Roboto; */
    font-size: 20px;
    letter-spacing: 0.015em;
    color: rgba(0, 0, 0, 0.7);    
}

.introduction-container button {
    background-color: rgba(73, 93, 255, 0.6);
    color: #3B3F66;
    font-size: 30px;
    /* font-family: 'Bree Serif', serif; */
    border: none;
    padding: 20px;
    width: fit-content;
    outline: none;
    cursor: pointer;
}

.introduction-container button:hover {
    background-color: rgba(73, 93, 255, 0.7);
}

.introduction-container #high-5 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
}


.introduction-container #high-5 img {
    height: 350px;
    right: 0px;
    position:absolute
}
#portal-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex: 2;
}

#portal-badge img {
    height: 350px;

    right: 0px;
    position: absolute; 
}

.regular-cont-main-frame {
    height: fit-content;
    width: calc(90% - 20px);
    border: 2px solid #f3f3f3;
    border-radius: 10px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 1fr;
    
}

.regualr-cont-main-frame-no-b {
    width: 100%;
    height: fit-content;
    width: calc(100%-20px);
    display: grid;
    grid-template-columns: 1fr 1fr;
}



#intro-text-b {
    font-size: 35px;
    padding: 1.5em 1em;
    background: #fcfafa;
    /* border-right: 2px solid #eee; */
}

#intro-text-c {
    color: #58BCA4;
    font-size: 35px;
    padding: 1.5em 1em;
}


.cats-mini-name {
    font-size: 40px;
    /* color: rgba(0, 0, 0, 0.7); */
}

.cats-mini-range {
    color: rgba(255, 255, 255, 0.8);
    font-style: normal;
    font-size: 20px;
    padding: 0.5em;
    border-radius: 5px;
    font-weight: bold;
} 



#cats-mini-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.cats-mini-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}

.testim-mini-container {
    display: flex;
    gap: 0.5em;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.testim-mini-name {
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    
    color: rgba(0, 0, 0, 0.7);
}

.textim-mini-comment {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.7);    
}

.testim-mini-date {
    font-size: 15px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.5);    
}

.testim-mini-container img {
    width: 120px;
}

.cats-mini-container-prime {
    display: flex;
    flex-direction: row;
    padding: 10px;
    align-items: center;
}

.arrow-icon {
    height: 30px;
    opacity: 0.7;
    cursor: pointer;
}

.arrow-icon:hover {
    opacity: 1;
}

.component-header-title {
    padding: 0.5em;
    background: #483699;
    color: #fff;
    width: fit-content;
    border-radius: 5px; 
    font-weight: bold;
    font-size: 20px;
}

.board-component-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5em;
    width: fit-content;
}

.youtube-video-container {
    display: flex;
    flex-direction: row;
    gap: 1em;
}

@media only screen and (max-width: 750px){

    .youtube-video-container {
        display: flex;
        flex-direction: column;
        gap: 2em;
    }

    #upper-image {
        height: fit-content;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    #upper-image #intro-first {
        width: 100%;
        margin-top: 4em;
    }

    .introduction-containerV2 h1{
        font-size: 3em;
        text-align: center;
    }
    .introduction-containerV2 #welcome-sentence{
        font-size: 1.5em;
        text-align: center;
        color: rgba(0, 0, 0, 0.7)
    }
    .regular-cont-main-frame {
        width: 100%;
        height: fit-content;
        width: calc(100%-20px);
        border-top: 1.5px solid #ddd;
        border-bottom: 1.5px solid #ddd;
        border-left: none;
        border-right: none;
        box-shadow: none;
        box-sizing: border-box;
        display: grid;
        border-radius: 0px;
        display: flex;
        flex-direction: column;
    }
    #intro-text-b {
        font-size: 35px;
        padding: 1em;
        border-right: none;
        /* border-bottom: 2px solid #eee; */
    }
    #cats-mini-container {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 1em;
        justify-content: space-around;
    }
    .arrow-icon {
        height: 30px;
        opacity: 0.7;
        cursor: pointer;
        transform: rotate(90deg);
    }
    .cats-mini-container-prime {
        display: flex;
        flex-direction: column;
        padding: 10px;
        align-items: center;
    }
    .introduction-containerV3 h1{
        font-size: 3em;
        text-align: center;
    }

    .introduction-containerV2 #welcome-sentence-mini{
        font-size: 1.5em;
        text-align: center;
        color: rgba(255, 255, 255, 0.7);
    }

    .introduction-containerV3{
        width: 90%;
    }
    #subjects-inner-container {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1em;
        padding: 1em;
    }

    .blank {
        display: none;
    }

    #background-slider-filter, #intro-first {
        border-radius: 0;
    }

}

