


hr {
    background-color: #f3f3f3;
    border: 0.5px solid #f3f3f3;
}

.details-box {
    display: flex;
    flex-direction: column;
    border: 1.5px solid #fff;
    box-sizing:border-box;
    background: #f3f3f3;
    gap: 1em;
    width: fit-content;
    border-radius: 10px;
    padding: 1em;
}


.details-box:hover {
    border: 1.5px solid #f3f3f3;
    box-shadow: 0px -10px 0px #f3f3f3;
    background: #fff;
    cursor: pointer;
}

.user-image {
    border: 1.5px solid #ddd;
    height: 100px;
    width: 100px;
    border-radius: 10px;
    
}

.personal-box {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    gap: 0.5em;
}

.tutors-collection {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1em;
    width: 100%;
}

.content-container-subjects {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.content-container-subjects ul {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.content-container-subjects ul li {
    color: rgba(0, 0, 0, 0.7);
}

.content-container-subjects ul li:hover {
    color: rgba(0, 0, 0, 1);
}

.option-box {
    border: 1px solid #f3f3f3;
    width: fit-content;
    padding: 1em;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.7);
}

.option-box:hover {
    box-shadow: 0px 0px 10px #eee;
}

.option-collection {
    /* display: grid; */
    /* grid-template-columns: repeat(3, 1fr); */
    gap: 1em;
    width: 100%;
}

.overview-section  {
    /* background: linear-gradient(162.06deg, rgba(79, 232, 204, 0.9) 15.46%, rgba(221, 222, 163, 0.9) 87.77%);; */
    /* background-color: #bbb; */
    /* padding: 1em; */
    /* position: sticky;
    top: 10px; */
    display: flex;
    flex-direction: column;
    gap: 1em;
    /* font-family: Roboto; */


}

.header {
    color: rgba(0, 0, 0, 0.8);
    font-size: 40px;
    width: fit-content;
}

.overview-section p {
    color: rgba(0, 0, 0, 1)
}



.sub-header {
    font-size: 25px;
}

.sub-beta-header {
    font-size: 20px;
    color: rgba(0, 0, 0, 1);
    font-weight: 500;
}

.main-text {

    color: rgba(0, 0, 0, 1)

}

.page-header {
    display: flex;
    flex-direction: column;
    position: relative;
}



.page-header p {
    color:rgba(255, 255, 255, 1);
    margin: 1em;
}

#black-grey-out {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
}

.subject-image {
    object-fit: cover;
    width: 100%;
    height: 60px;
}

#search-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 0.5em 0;
    border-bottom: 1.5px solid #ddd;
    align-items: center;
    justify-content: space-between;
}

#search-container div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
}

#search-container input {
    border: none;
    box-shadow: none;
    margin: none;
    color: rgba(0, 0, 0, 0.7);
    padding: 0
}

#search-container img {
    height: 20px;
    opacity: 50%
}

.paper-container {
    
    padding: 0.5em 1em;
    background: #f3f3f3;
    display: flex;
    justify-content: space-between;
    opacity: 0.8;
    border: 1.5px solid #fff;
    cursor: pointer;
    align-items: center;

}

.paper-container:hover {
    opacity: 1;
    background: #fff;
    box-shadow: 10px 0px 0px #f3f3f3;
    border: 1.5px solid #f3f3f3;
    box-sizing:border-box;
}

.right-section {
    display: flex;
    flex-direction: row;
    gap: 1em;
    align-items: center;
}

.paper-container img {
    height: 1.5em;
}

.paper-container .board-name {
    font-weight: 700;
}

.page-main-subjects-list-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1em;
}

.page-main-subjects-list-main-container {
    display: flex;
    flex-direction: column;
    gap: 1em;
    justify-content: space-between;
}

@media only screen and (max-width: 750px){ 
    .page-main {
        display: flex;
        flex-direction: column;
        gap: 2em;
        padding: 0px 30px;
        width: calc(100% - 60px);
        /* padding: 0 1em; */
        position: relative;
    }
    .tutors-collection {
        display: flex;
        flex-direction: column;
        gap: 1em;
        width: fit-content;
        /* width: 100%; */
    } 
    .details-box {
        display: flex;
        flex-direction: column;
        border: 1.5px solid #fff;
        box-sizing:border-box;
        background: #f3f3f3;
        gap: 1em;
         border-radius: 10px;
        padding: 1em;
    }    
    .page-main-subjects-list-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0px;
        gap: 1.5em;
    }

    .page-main-subjects-list-container .subject-container-index {
        width: 100%;
        display:flex;
        justify-content: center;
        align-items: center;
    }
}