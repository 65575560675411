header {
  padding: 1em;
  position: fixed;
  top: 0;
  width: calc(100% - 2em);
  z-index: 5;
  backdrop-filter: blur(2px);
  background-color:rgba(255, 255, 255, 0.7);
}
  
.header-links {
  opacity: 0.7;
  font-size: 18px;
  cursor: pointer;
}

.header-links:hover {
  opacity: 1;
}