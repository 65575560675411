* {
  margin: 0;
}

@font-face {
  font-family: sfb;
  src: url(../fonts/sfb.ttf);
}

@font-face {
  font-family: sfr;
  src: url(../fonts/sfr.ttf);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-bottom: 5em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6, p {
  font-family: sfr;
}

.midweighttext {
  font-weight: 700;
}

.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.onegap {
  gap: 1em;
}

.twogap {
  gap: 2em;
}

.spaceinbetween {
  justify-content: space-between;
}

.verticalalign {
  align-items: center;
}

.margintop3 {
  margin-top: 5em;
}

.page-main {
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  gap: 2em;
  width: 70%;
  position: relative;
}

.page-centered {
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;
  width: 100%;
}

.present_button {
  padding: 1em;
  border: 2px solid #F3F3F3;
  background-color: #FFF;
  cursor: pointer;
}

.present_button:hover {
  border: 2px solid #F3F3F3;
  background-color: #F3F3F3;
}