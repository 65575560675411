
.full-image {
    height: 60vh;
    opacity: 1;
    transition: opacity 0.5s linear; 
    object-fit: cover;
}

#slider-container {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    /* height: 100%; */
    border-radius: 1em;

}

@media only screen and (max-width: 750px){
    #slider-container {
        border-radius: 0;
    } 
}